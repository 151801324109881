import React, { useState, useEffect } from 'react'
import { Seo, Button } from '@cgtalks/common/components'
import { API, Axios } from '@cgtalks/common/utils'
import Layout from '../molecules/layout'
import AboutCg from '../molecules/guests/'
import {
  BannerContainer,
  Form,
  InputRow,
  GuestForm,
  ShowDialog,
  DialogContnt,
  ErrorText,
} from './guest-recommendation.atom'
import { isTest } from '@cgtalks/common/service'

function GuestRecommendation(props) {
  const [name, setName] = useState('') // 姓名
  const [referrer, setReferrer] = useState('') // 推荐人
  const [phone, setPhone] = useState('') // 联系方式
  const [guestsPhone, setGuestsPhone] = useState('') // 嘉宾联系方式
  const [cgSocialMedia, setCgSocialMedia] = useState('') // 社媒链接
  const [reason, setReason] = useState('') // 推荐理由
  const [submitted, setSubmitted] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(true)
  const instance = isTest() ? new Axios(API.auth.testHost) : new Axios(API.auth.host)

  const [isNameValid, setIsNameValid] = useState(false)
  const [isReferrerValid, setIsReferrerValid] = useState(false)
  const [isPhoneValid, setIsPhoneValid] = useState(false)
  const [isGuestsPhoneValid, setIsGuestsPhoneValid] = useState(false)
  const [isSocialMediaValid, setIsSocialMediaValid] = useState(false)
  const [isReasonValid, setIsReasonValid] = useState(false)

  const [nameValidText, setNameValidText] = useState('')
  const [referrerValidText, setReferrerValidText] = useState('')
  const [phoneValidText, setPhoneValidText] = useState('')
  const [guestsPhoneValidText, setGuestsPhoneValidText] = useState('')
  const [socialMediaValidText, setSocialMediaValidText] = useState('')
  const [reasonValidText, setReasonValidText] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    // 表单验证
    if (
      !name ||
      !referrer ||
      !phone ||
      !cgSocialMedia ||
      !reason ||
      isNameValid ||
      isReferrerValid ||
      isPhoneValid ||
      isGuestsPhoneValid ||
      isSocialMediaValid ||
      isReasonValid
    ) {
      return
    }
    if (
      !/^1[3456789]\d{9}$/.test(phone) ||
      (guestsPhone && !/^1[3456789]\d{9}$/.test(guestsPhone))
    ) {
      return
    }

    // 构建请求参数
    const data = {
      name,
      referrer,
      phone,
      guestsPhone,
      socialMedia: cgSocialMedia,
      reason,
      type: 0,
      createTime: Date.now(),
    }
    instance
      .post(API.event.guest, {
        data,
        event: 'cgForm',
      })
      .then((res) => {
        if (res.code === 200) {
          setSubmitted(true)
        }
      })
  }

  const confirm = () => {
    setSubmitted(false)
    setName('')
    setReferrer('')
    setPhone('')
    setGuestsPhone('')
    setCgSocialMedia('')
    setReason('')
  }

  const handleNameChange = (e) => {
    const value = e.target.value
    setName(value)
    if (value.trim() === '') {
      setIsNameValid(true)
      setNameValidText('必填')
    } else if (value.trim().length > 200) {
      setIsNameValid(true)
      setNameValidText('输入不超过200字符')
    } else {
      setIsNameValid(false)
      setNameValidText('')
    }
  }

  const handleReferrerChange = (e) => {
    const value = e.target.value
    setReferrer(value)
    if (value.trim() === '') {
      setIsReferrerValid(true)
      setReferrerValidText('必填')
    } else if (value.trim().length > 200) {
      setIsReferrerValid(true)
      setReferrerValidText('输入不超过200字符')
    } else {
      setIsReferrerValid(false)
      setReferrerValidText('')
    }
  }

  const handlePhoneChange = (e) => {
    const value = e.target.value
    setPhone(value)
    if (value.trim() === '') {
      setIsPhoneValid(true)
      setPhoneValidText('必填')
    } else if (!/^1[3456789]\d{9}$/.test(value)) {
      setIsPhoneValid(true)
      setPhoneValidText('手机号码格式不正确')
    } else {
      setIsPhoneValid(false)
      setPhoneValidText('')
    }
  }

  const handleGuestPhoneChange = (e) => {
    const value = e.target.value
    setGuestsPhone(value)
    if (value && !/^1[3456789]\d{9}$/.test(value)) {
      setIsGuestsPhoneValid(true)
      setGuestsPhoneValidText('手机号码格式不正确')
    } else {
      setIsGuestsPhoneValid(false)
      setGuestsPhoneValidText('')
    }
  }

  const handleSocialMediaChange = (e) => {
    const value = e.target.value
    setCgSocialMedia(value)
    if (value.trim() === '') {
      setIsSocialMediaValid(true)
      setSocialMediaValidText('必填')
    } else if (value.trim().length > 500) {
      setIsSocialMediaValid(true)
      setSocialMediaValidText('输入不超过500字符')
    } else {
      setIsSocialMediaValid(false)
      setSocialMediaValidText('')
    }
  }

  const handleReasonChange = (e) => {
    const value = e.target.value
    setReason(value)
    if (value.trim() === '') {
      setIsReasonValid(true)
      setReasonValidText('必填')
    } else if (value.trim().length > 200) {
      setIsReasonValid(true)
      setReasonValidText('输入不超过200字符')
    } else {
      setIsReasonValid(false)
      setReasonValidText('')
    }
  }

  useEffect(() => {
    if (
      isNameValid ||
      isReferrerValid ||
      isPhoneValid ||
      isGuestsPhoneValid ||
      isSocialMediaValid ||
      isReasonValid ||
      !name ||
      !referrer ||
      !phone ||
      !cgSocialMedia ||
      !reason
    ) {
      setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
    }
  }, [
    isNameValid,
    isReferrerValid,
    isPhoneValid,
    isGuestsPhoneValid,
    isSocialMediaValid,
    isReasonValid,
    name,
    referrer,
    phone,
    cgSocialMedia,
    reason,
  ])

  const { location } = props
  return (
    <Layout location={location}>
      <Seo
        title='推荐嘉宾-泛CG聚未来-Renderbus瑞云渲染'
        keywords='泛CG, CG技术线上分享会,cg分享网,CG分享平台'
        description='泛CG聚未来，分线上直播、线下交流会，是一个属于CG人的自由分享、交流平台，推荐嘉宾，我们将为您邀请对方来进行技术交流、分享、线下交流，赶紧推荐吧。'
        sharePostSlug='guest-recommendation.html'
      />
      <BannerContainer />
      <GuestForm>
        <h1>推荐嘉宾</h1>
        <Form onSubmit={handleSubmit}>
          <InputRow>
            <label htmlFor='name'>*姓名</label>
            <input
              id='name'
              type='text'
              value={name}
              onBlur={handleNameChange}
              onChange={handleNameChange}
              placeholder='请输入你的姓名'
            />
          </InputRow>
          {isNameValid && <ErrorText>{nameValidText}</ErrorText>}
          <InputRow>
            <label htmlFor='referrer'>*推荐人</label>
            <input
              id='referrer'
              type='text'
              value={referrer}
              onBlur={handleReferrerChange}
              onChange={handleReferrerChange}
              placeholder='请输入你推荐人的姓名'
            />
          </InputRow>
          {isReferrerValid && <ErrorText>{referrerValidText}</ErrorText>}
          <InputRow>
            <label htmlFor='phone'>*联系方式</label>
            <input
              id='phone'
              type='tel'
              value={phone}
              pattern='[0-9]{11}'
              onBlur={handlePhoneChange}
              onChange={handlePhoneChange}
              placeholder='请输入你的联系方式'
            />
          </InputRow>
          {isPhoneValid && <ErrorText>{phoneValidText}</ErrorText>}
          <InputRow>
            <label htmlFor='guestsPhone'>嘉宾联系方式</label>
            <input
              id='guestsPhone'
              type='tel'
              value={guestsPhone}
              pattern='[0-9]{11}'
              onBlur={handleGuestPhoneChange}
              onChange={handleGuestPhoneChange}
              placeholder='请输入你推荐嘉宾的联系方式'
            />
          </InputRow>
          {isGuestsPhoneValid && <ErrorText>{guestsPhoneValidText}</ErrorText>}
          <InputRow>
            <label htmlFor='cgSocialMedia'>*社媒链接</label>
            <input
              id='cgSocialMedia'
              type='text'
              value={cgSocialMedia}
              onBlur={handleSocialMediaChange}
              onChange={handleSocialMediaChange}
              placeholder='请输入他/她的网站/社媒链接'
            />
          </InputRow>
          {isSocialMediaValid && <ErrorText>{socialMediaValidText}</ErrorText>}
          <InputRow className='text-area'>
            <label htmlFor='reason'>*推荐理由</label>
            <textarea
              id='reason'
              value={reason}
              onBlur={handleReasonChange}
              onChange={handleReasonChange}
              placeholder='请简单说下您推荐他/她的理由，比如擅长领域，你是从哪里认识他/她的'
              maxLength={400}
            />
          </InputRow>
          {isReasonValid && <ErrorText mt={'3px'}>{reasonValidText}</ErrorText>}
          <Button disabled={disableSubmit}>提交</Button>
        </Form>
        {submitted && (
          <ShowDialog>
            <DialogContnt>
              <p>感谢您的提交，我们会尽快联系您的！</p>
              <button onClick={confirm}>好的</button>
            </DialogContnt>
          </ShowDialog>
        )}
      </GuestForm>
      <AboutCg />
    </Layout>
  )
}

export default GuestRecommendation
